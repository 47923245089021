<template>
  <section>
    <section class="top">
      <div>
        <p class="msg_title">
          Message
        </p>
        <p class="msg_subtitle">
          Create New Message
        </p>
      </div>
      <div>
        <p class="msg_subtitle">
          SMS Credit Usage
        </p>
        <p class="msg_subtitle">
          {{ smsCreditUse }}
        </p>
      </div>
      <div class="top_btn">
        <VBtn
          round
          large
          class="shadow mr-0"
          color="white"
          @click="broadcastMsg()"
        >
          Broadcast
        </VBtn>
        <VBtn
          round
          large
          class="shadow"
          color="white"
          @click="confSend = true"
        >
          Send
        </VBtn>
      </div>
    </section>
    <section class="content">
      <div class="grid">
        <!-- TITLE -->
        <VCard class="msg_card shadow">
          <div class="msg_card_content">
            <div class="title_help_btn">
              <VBtn
                icon
                @click="titleInfo =true"
              >
                <VIcon color="primary">
                  info
                </VIcon>
              </VBtn>
            </div>
            <p class="msg_main_title">
              Title
            </p>
            <p class="msg_main_subtitle">
              Conversation Name
            </p>
          </div>
          <div>
            <VForm
              ref="formTitle"
              v-model="valid"
              lazy-validation
              @submit.prevent
            >
              <VTextField
                v-model="title"
                solo
                flat
                placeholder="Ex: Holiday Announcement"
                :hide-details="true"
                :rules="[rules.required]"
              />
            </VForm>
          </div>
        </VCard>
        <!-- BODY -->
        <VCard class="msg_card shadow">
          <div class="msg_card_content mb-1">
            <p class="msg_main_title">
              Message
            </p>
          </div>
          <div>
            <VForm
              ref="formBody"
              v-model="valid"
              lazy-validation
              @submit.prevent
            >
              <VTextarea
                v-model="message"
                placeholder="Ex: The school will remain closed on 26 Jan 2019"
                solo
                flat
                rows="2"
                maxlength="160"
                full-width
                counter
                :hide-details="false"
                :rules="[rules.required]"
                :auto-grow="true"
              />
            </VForm>
          </div>
        </VCard>
        <!-- NUMBER -->
        <VCard class="msg_card shadow">
          <div class="msg_card_content">
            <p class="msg_main_title">
              Number
            </p>
            <p class="msg_main_subtitle">
              Phone Number
            </p>
          </div>
          <div>
            <VTextField
              v-model="phNumber"
              solo
              flat
              placeholder="Ex: 0123456789"
              append-icon="add"
              counter
              maxlength="10"
              type="tel"
              :hide-details="true"
              :mask="phNumberFormat"
              @click:append="addNumb(phNumber)"
              @keyup.enter="addNumb(phNumber)"
            />
          </div>
          <div class="grid_chip">
            <div
              v-for="(phNo, index) in phNumberList"
              :key="index"
            >
              <VChip
                close
                color="chip"
                dark
                @input="remNumb(index)"
              >
                <span>{{ phNo }}</span>
              </VChip>
            </div>
          </div>
        </VCard>
      </div>
    </section>
    <div>
      <VDialog
        v-model="confSend"
        width="300"
        persistent
      >
        <ConfirmAction
          @confirm="sendMsg()"
          @close="confSend = false"
        />
      </VDialog>
    </div>
    <div>
      <VDialog
        v-model="isSending"
        width="300"
        persistent
      >
        <VCard
          v-if="loadStatus === 'sending'"
          class="notification_card elevation-20"
        >
          <p>Processing</p>
          <span>Sending SMS, Please Wait...</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="primary"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'sent'"
          class="notification_card elevation-20"
        >
          <p>Sent</p>
          <span>SMS Sent Successfully</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="primary"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'netfail'"
          class="notification_card elevation-20"
        >
          <p>Internet Issue</p>
          <span>Please Try Again Later</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="red"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'fail'"
          class="notification_card elevation-20"
        >
          <p>Error</p>
          <span>An Error Occured</span>
          <div class="notification_ico">
            <VBtn
              depressed
              round
              color="primary"
              @click="reloadPage()"
            >
              Reload
            </VBtn>
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'info'"
          class="notification_card elevation-20"
        >
          <p>Info</p>
          <span>SMS delivery can sometimes take upto 10 minutes depending on user's network</span>
          <div class="notification_ico">
            <VBtn
              depressed
              round
              color="primary"
              @click="gotIt()"
            >
              Got It
            </VBtn>
          </div>
        </VCard>
      </VDialog>
    </div>
    <div>
      <VSnackbar
        v-model="snackbar"
        color="secondary"
        :timeout="2500"
        :multi-line="true"
      >
        {{ snackbarTxt }}
        <VBtn
          color="primary"
          depressed
          style="width:100px;"
          round
          @click="snackbar = false"
        >
          Close
        </VBtn>
      </VSnackbar>
    </div>
    <div>
      <VDialog
        v-model="titleInfo"
        width="300"
        persistent
      >
        <VCard class="notification_card elevation-20">
          <p>Info</p>
          <span class="mb-0">The <strong>TITLE</strong> is required to keep track of messages and is
            <strong>NOT</strong> sent to the recipient.</span>
          <span class="mt-2"> For example "Holiday Announcement" only you will be able to view it
            and search messages using the <strong>TITLE</strong>.</span>
          <div class="notification_ico">
            <VBtn
              depressed
              round
              color="primary"
              @click="titleInfo = false"
            >
              Got It
            </VBtn>
          </div>
        </VCard>
      </VDialog>
      <VDialog
        v-model="isLoading"
        width="300"
        persistent
      >
        <VCard class="notification_card elevation-20">
          <p>Loading</p>
          <span>Fetching Data, Please Wait...</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="primary"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
      </VDialog>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import api from '../../conf/api';
import ConfirmAction from '../../components/confirmAction.vue';

export default {
  components: {
    ConfirmAction,
  },
  data: () => ({
    isLoading: false,
    titleInfo: false,
    snackbar: false,
    snackbarTxt: '',
    isSending: false,
    smsBal: '',
    loadStatus: '',
    title: '',
    message: '',
    phNumber: '',
    phNumberList: [],
    // msgType Custom / Group
    msgDate: moment().format('DD-MM-YYYY'),
    valid: true,
    confSend: false,
    phNumberFormat: '##########',
    rules: {
      phoneNumber:
        (val) => {
          const phNoPattern = /[0-9]/;
          return phNoPattern.test(val) || 'Invalid phone number.';
        },
      required: (val) => {
        if (val == null) {
          return 'Required Field';
        }
        if (val === '') {
          return 'Required Field';
        }
        return true;
      },
    },
  }),
  computed: {
    smsCreditUse() {
      return this.phNumberList.length;
    },
    msgType() {
      if (this.phNumberList.length > 1) {
        return 'Group';
      }
      return 'Custom';
    },
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    addNumb(phNo) {
      const phNoInput = phNo.trim();
      if (phNoInput.length >= 1) {
        this.phNumberList.unshift(phNoInput);
      }
      this.phNumber = '';
    },
    remNumb(phNo) {
      this.phNumberList.splice(phNo, 1);
    },
    sendMsg() {
      this.confSend = false;
      const smsSchema = {
        title: this.title,
        message: this.message,
        phNumber: this.phNumberList,
        smsType: this.msgType,
        date: this.msgDate,
      };
      if (
        this.$refs.formTitle.validate()
        && this.$refs.formBody.validate()
        && this.phNumberList.length >= 1
      ) {
        this.isSending = true;
        this.loadStatus = 'sending';
        axios.post(`${api}/sms/send`, smsSchema).then(() => {
          this.loadStatus = 'sent';
          setTimeout(() => {
            this.loadStatus = 'info';
          }, 1000);
        })
          .catch((e) => {
            if (e) {
              if (e.code === 'auth/network-request-failed') {
                this.loadStatus = 'netfail';
                setTimeout(() => {
                  this.$router.push('/message');
                }, 2500);
              }
            } else {
              this.loadStatus = 'fail';
            }
          });
      } else {
        this.snackbar = true;
        this.snackbarTxt = 'Please Fill In All The Fields';
      }
    },
    gotIt() {
      this.isSending = false;
      setTimeout(() => {
        this.$router.push('/message');
      }, 200);
    },
    broadcastMsg() {
      this.isLoading = true;
      axios.get(`${api}/sms/broadcast/list`).then((res) => {
        res.data.data.forEach((el) => {
          this.phNumberList.push(el.phonePrimary);
        });
        this.isLoading = false;
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Message/new';
</style>
